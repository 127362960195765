<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="成员管理"/>
        <div style="margin-top: 20px">
            <div>
                <div class="pull-right">
                    <el-radio-group v-model="utype" size="small" @change="getData">
                        <el-radio-button label="">全部</el-radio-button>
                        <el-radio-button label="0">未发送</el-radio-button>
                        <el-radio-button label="1">待发送</el-radio-button>
                        <el-radio-button label="2">已发送</el-radio-button>
                        <el-radio-button label="-1">发送失败</el-radio-button>
                    </el-radio-group>
                    <router-link :to="`/main/certs/u/${$route.params.id}`" style="margin: 0 10px">
                        <el-button size="small">批量导入</el-button>
                    </router-link>
                    <el-button size="small" @click="handleAdd">添加成员</el-button>
                </div>
                <div style="margin-top: 20px">
                    <el-button type="primary" size="small" :loading="mark_loading" @click="markSendmail">自动发邮件
                    </el-button>
                    <el-button type="primary" size="small" :loading="download_loading" @click="downloadCerts">批量下载证书
                    </el-button>
                </div>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column width="350" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-input v-model="keywords" size="mini" @change="handleSearch(scope)" placeholder="输入姓名关键字搜索"/>
                    </template>
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handlePreview(scope.$index, scope.row)">预览证书</el-button>
                        <el-button size="mini" @click="handleSendmail(scope.$index, scope.row)"
                                   :loading="scope.row.loading">发送邮件
                        </el-button>
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                    width="180">
                            <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                            <div>
                                <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">确定
                                </el-button>
                                <el-button size="mini" type="default"
                                           @click="$refs[`popover-${scope.$index}`].doClose()">取消
                                </el-button>
                            </div>
                            <el-button size="mini" type="danger" slot="reference">删除</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[5, 10, 20, 30, 50, 100]"
                               :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" label-width="100px" style="padding-right: 30px">
                <el-form-item label="姓名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="form.idnum"></el-input>
                </el-form-item>
                <el-form-item label="证书编号">
                    <el-input v-model="form.certnum"></el-input>
                </el-form-item>
                <el-form-item label="邮箱地址">
                    <el-input v-model="form.email"></el-input>
                </el-form-item>
                <el-form-item label="证书日期">
                    <el-input v-model="form.certDate"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="预览" :visible.sync="previewVisible" width="50%">
            <div style="text-align: center">
                <img :src="previewUrl" style="max-width: 100%;max-height: 400px;">
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import LoadingGif from '@/assets/loading.gif'

    export default {
        data() {
            return {
                loading: false,
                mark_loading: false,
                download_loading: false,
                utype: '',
                previewUrl: '',
                form: {
                    name: '',
                    idnum: '',
                    certnum: '',
                    email: '',
                    certDate: '',
                },
                table: {
                    columns: [
                        {title: '姓名', field: 'name', width: '110'},
                        {title: '身份证号', field: 'idnum', width: '180'},
                        {title: '证书编号', field: 'certnum', width: '180'},
                        {title: '邮箱地址', field: 'email', width: ''},
                        {title: '证书生成状态', field: 'generate', width: '120', formatter: this.certStatus},
                        {title: '邮件发送状态', field: 'status', width: '120', formatter: this.statusFormat},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                keywords: '',
                selected: [],
                dialogVisible: false,
                previewVisible: false,
            }
        },
        methods: {
            certStatus() {
                return "已生成"
            },
            statusFormat(row) {
                let status = ''
                switch (row.status) {
                    case -1:
                        status = '发送失败'
                        break;
                    case 0:
                        status = '未发送'
                        break;
                    case 1:
                        status = '待发送'
                        break;
                    case 2:
                        status = '已发送'
                        break;
                    default:
                        status = '未知'
                        break;
                }
                return status
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.table.page = 1
                this.getData()
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/cert/member/${this.$route.params.id}/?page=${this.table.page}&pageSize=${this.table.pageSize}&status=${this.utype}&keywords=${this.keywords}`)
                if (resp.data.state != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.content
                    this.table.total = resp.data.data.totalElements
                }
                this.loading = false
            },
            async onSubmit() {
                this.loading = true
                let resp
                if (this.form.id) {
                    resp = await this.$http.put(`/cert/member/${this.$route.params.id}/${this.form.id}`, this.form)
                } else {
                    resp = await this.$http.post(`/cert/member/${this.$route.params.id}/`, this.form)
                }
                if (resp.data.state != "200") {
                    this.$message.error(resp.data.msg)
                    this.loading = false
                } else {
                    this.getData()
                    this.dialogVisible = false
                }
            },
            handleAdd() {
                this.form = {name: '', idnum: '', certnum: '', email: ''}
                this.dialogVisible = true
            },
            handleEdit(idx, row) {
                console.log(row)
                this.form = row
                this.edit_username = false
                this.edit_password = false
                this.edit_phone = true
                this.dialogVisible = true
            },
            async handleSendmail(idx, row) {
                this.$set(row, 'loading', true)
                const resp = await this.$http.post(`/cert/sendmail/${row.id}`)
                console.log(resp)
                this.$set(row, 'loading', false)
                this.$message.success(resp.data.msg)
            },
            async handlePreview(idx, row) {
                this.previewVisible = true
                this.previewUrl = LoadingGif
                const resp = await this.$http.get(`/cert/preview/${row.id}`, {responseType: 'arraybuffer'})
                console.log(resp)
                this.previewUrl = 'data:image/png;base64,' + btoa(new Uint8Array(resp.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
            },
            async handleDelete(idx, row) {
                this.loading = true
                const resp = await this.$http.delete(`/cert/member/${row.id}`)
                console.log(resp)
                this.getData()
            },
            handleSelectionChange(val) {
                console.log(val)
                this.selected = val
            },
            async markSendmail() {
                if (this.selected.length === 0) {
                    this.$message.error("先选择学员")
                } else {
                    this.mark_loading = true
                    let ids = []
                    this.selected.forEach(row => {
                        ids.push(row.id)
                    });
                    const resp = await this.$http.post(`/cert/member/mark/sendmail`, this.selected)
                    console.log(resp)
                    this.$message.success(ids.length + " 条记录已经标记发送邮件")
                    this.mark_loading = false
                }
            },
            async downloadCerts() {
                if (this.selected.length === 0) {
                    this.$message.error("先选择学员")
                    return

                }
                this.download_loading = true
                let ids = []
                this.selected.forEach(row => {
                    ids.push(row.id)
                });
                // let defaultFileName = "123.zip"
                // let fileName;
                // const { data: body } = resp;
                // // 这里要注意 content-disposition全小写
                //
                // if (body) {
                //     let file = new Blob([body], {
                //         type: "application/octet-stream",
                //     });
                //     let downloadElement = document.createElement("a");
                //     let href = window.URL.createObjectURL(file);
                //     downloadElement.href = href;
                //     downloadElement.download = decodeURIComponent(fileName || defaultFileName);
                //     document.body.appendChild(downloadElement);
                //     downloadElement.click();
                //     document.body.removeChild(downloadElement);
                //     window.URL.revokeObjectURL(href);
                // } else {
                //     // 下载失败的处理
                // }
                try {
                    const resp = await this.$http({
                        url: `/cert/member/download/certs`,
                        method: 'post',
                        responseType: 'blob',
                        data: ids
                    })
                    const blob = new Blob([resp.data], {type: resp.headers['content-type']});
                    let fileName = 'certs.zip'
                    const contentDisposition = resp.headers["content-disposition"];
                    if (contentDisposition) {
                        // filename 同样全小写
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, "");
                        }
                    }
                    const link = document.createElement('a')
                    link.download = fileName
                    link.style.display = 'none'
                    link.href = window.URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                    document.body.removeChild(link)
                } catch (e) {
                    this.$message.error(e.message)
                    console.log(e)
                }
                this.download_loading = false
            },
            handleSearch(e) {
                console.log(e)
                this.getData()
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .pull-right {
        float: right
    }
</style>
